import React from 'react'

import { Link } from 'gatsby'
import { RootLayout } from 'src/layouts'
import { Wrapper, HBox } from 'src/ui/layout'
import { Header, Body } from 'src/ui/typography'
import { Meta } from 'src/features/seo'

type Props = {}

const NotFound: React.FC<Props> = () => {
  return (
    <RootLayout>
      <Meta />
      <HBox height={100} />
      <Wrapper>
        <Header size="h1">404</Header>
        <HBox />
        <Header size="h2">Страница не найдена</Header>
        <HBox />
        <Body>
          К сожалению запрашиваемая вами страница не найдена, начните с{' '}
          <Link to="/">главной</Link>
        </Body>
      </Wrapper>
      <HBox height={100} />
    </RootLayout>
  )
}

export default NotFound
